@use '@carbon/react';
@use '@carbon/styles';
@use '@carbon/styles/scss/grid';
@use '@carbon/type';


@include grid.flex-grid();

.cds--padding-0 {
    padding: 0;
}

.main-content {
    margin-left: 16rem;
    margin-top: 48px;
}

.side-nav {
    height: auto;

    .cds--side-nav__items {
        &:last-child {
            flex: 0 0 auto;
        }

        svg {
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}

.login-form {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 30px 0;

    .login-box {
        margin: 0 auto;
        max-width: 400px;
        background-color: #fff;
        padding: 30px;
        width: 100%;

        .title {
            margin-bottom: 30px;
            font-weight: 500;
        }

        .remember {
            display: flex;
            align-items: center;

            .cds--checkbox-wrapper {
                flex: 0 0 auto;
                margin-right: 5px;
            }
        }
    }
}

.red-text {
    color: red;
    font-size: 14px;
}

.login {
  // background: #eeeeee;
  // height: 100vh;
  // width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-background {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dat-text {
  margin-top: 24px;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  color: #2564dd;
}

.login-google-btn {
  background: #4285f4;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
}

// .login-with-google {
//   margin-left: auto;
//   margin-right: auto;
//   width: 100%;
// }

.single-line-text {
  white-space: nowrap;
}